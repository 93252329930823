export default [
  {
    name: 'LineSystemSignup',
    path: '/line/system/signup',
    component: () => import('@/pages/LineSystem/Signup'),
    meta: {
      title: '管理帳號連結',
      isSupportMobile: true
    }
  },
  {
    path: '/line/system/:id',
    name: 'LineSystem',
    component: () => import('@/layouts/LineSystem/index'),
    meta: {
      title: '管理後台',
      isSupportMobile: true
    },
    props: true,
    children: [
      {
        name: 'LineSystemVisitRecord',
        path: '',
        component: () => import('@/pages/LineSystem/VisitRecord.vue'),
        meta: {
          title: '拜訪記錄',
          isSupportMobile: true
        }
      },
      {
        name: 'LineSystemCreateVisitRecord',
        path: '/create-visit-record',
        component: () => import('@/pages/LineSystem/CreateVisitRecord.vue'),
        meta: {
          title: '新增拜訪記錄',
          isSupportMobile: true
        }
      },
    ]
  }
]
