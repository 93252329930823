export default [
  {
    name: 'lineSignup',
    path: '/line/metasens/signup',
    component: () => import('@/pages/LineMetasens/Signup'),
    meta: {
      title: '帳號連結',
      isSupportMobile: true
    }
  },
  {
    path: '/line/metasens/:id',
    name: 'lineMetasens',
    component: () => import('@/layouts/LineMetasens/index'),
    meta: {
      title: 'Metasens',
      isSupportMobile: true
    },
    props: true,
    children: [
      {
        name: 'lineMetasensSummary',
        path: '',
        component: () => import('@/pages/LineMetasens/Summary'),
        meta: {
          title: '量測記錄',
          isSupportMobile: true
        }
      },
      {
        name: 'lineMetasensReport',
        path: 'report/:reportId',
        component: () => import('@/pages/LineMetasens/Report'),
        meta: {
          title: '量測報告',
          isSupportMobile: true
        }
      }
    ]
  },
]
