<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { setToken, getToken } from "@/utils/auth";

export default {
  name: "App",
  computed: {
    ...mapGetters("system", {
      isMobile: "isMobile",
      isShowMobileNotification: "isShowMobileNotification",
      isShowMobileSimpleNotification: "isShowMobileSimpleNotification",
    }),
    isNotoFontFamily() {
      return this.$i18n.locale !== "en-US";
    },
  },
  async created () {
    await this.$store.dispatch('system/setMobileDetect')
  },
  mounted() {
    this.getScreenWidth();
    this.getScreenHeight();
    window.addEventListener("resize", this.getScreenWidth);
    window.addEventListener("resize", this.getScreenHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getScreenWidth);
    window.removeEventListener("resize", this.getScreenHeight);
  },
  methods: {
    shouldResetCookiesExpires() {
      // NOTE: 進入 web 重置 token 的有效期限
      const token = getToken();
      if (token) setToken(token);
    },
    // 設置 螢幕高度 vh 使其在手機版網頁也能正確響應
    getScreenHeight() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      this.$store.commit("system/SET_SCREEN_HEIGHT", vh * 100);
    },
    // 設置 螢幕寬度 vw 使其在手機版網頁也能正確響應
    getScreenWidth() {
      // First we get the viewport width and we multiple it by 1% to get a value for a vw unit
      let vw = window.innerWidth * 0.01;
      // Then we set the value in the --vw custom property to the root of the document
      document.documentElement.style.setProperty("--vw", `${vw}px`);
      this.$store.commit("system/SET_SCREEN_WIDTH", vw * 100);
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 16px !important;
}

.v-application--wrap {
  position: absolute;
  height: calc(var(--vh, 1vh) * 100) !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;
  width: 100%;
}
</style>