import liff from '@line/liff'

const getDefaultState = () => (
  {
    loading: {
      status: false,
      time: null,
      orgData: null
    },
    lineUser: {
      isLoggedIn: false,
      profile: {
        userId: '',
        displayName: '',
        pictureUrl: '',
        statusMessage: ''
      },
      language: null
    }
  }
)

const state = getDefaultState()

const getters = {
  getLineUser: state => {
    return state.lineUser
  }
}

const mutations = {
  SET_LINE_LOGGED_STATUS: (state, bool) => {
    state.lineUser.isLoggedIn = bool
  },
  SET_LINE_USER_PROFILE: (state, object) => {
    state.lineUser.profile = Object.assign(state.lineUser.profile, object)
  },
  SET_LOADING_INFO: (state, { status = false, time = new Date().getTime(), orgData = null }) => {
    state.loading.status = status
    state.loading.time = time
    state.loading.orgData = orgData
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async initializeLiff({ commit, state }, liffIdParam) {
    const liffId = liffIdParam || process.env.VUE_APP_LINE_METASENS_LIFFID
    try {
      await liff.init({
        liffId: liffId
      })
      const isLoggedIn = await liff.isLoggedIn()
      commit('SET_LINE_LOGGED_STATUS', isLoggedIn)
      if (isLoggedIn) {
        const profile = await liff.getProfile()
        commit('SET_LINE_USER_PROFILE', profile)
      }
      return state.lineUser
    } catch (error) {
      return error
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}