import Vue from 'vue'
import Router from 'vue-router'
import Main from './main'
import Patient from './patient'
import Line from './line'
import LineSystem from './lineSystem'

Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_ASSETS_PUBLIC_PATH ? process.env.VUE_APP_ASSETS_PUBLIC_PATH : '/',
  routes: [
    ...Main,
    ...Patient,
    ...Line,
    ...LineSystem
  ]
})

router.beforeEach(async (to, from, next) => {
  // 檢查路由是否屬於 LineSystem
  if (to.name && to.name.startsWith('LineSystem')) { // 假設 LineSystem 路由的名稱前綴是 'lineSystem'
    document.title = '康立安管理系統';
  }
  next();
})

export default router

